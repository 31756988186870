export default function reducer(state,{type,payload}){
    switch(type){
        case 'AUTHORIZE':
            return{
                ...state,
                isAuthorized:true,
                surveyContainer:payload
            };
        case 'IS_AUTHORIZED':
            return{
                ...state,
                isAuthorized:payload
            };
        case 'UNAUTHORIZE':
            return{
                ...state,
                isAuthorized:false,
                surveyContainer:null
            };
        case 'SET_LANGUAGE':
            return {
                ...state,
                selectedLanguage:payload
            }
        
        default:
            return state;
    }
}