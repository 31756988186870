import React, { useState } from 'react';
import './pages.css';
import Fade from '@material-ui/core/Fade';
import LanguageSelectionPage from '../LanguageSelectionPage';
import { GetPageText } from '../LanguageTextResolver';
import { Typography, Box, Button } from '@material-ui/core';
import Navbar from '../Navbar';

function Page1(props) {
  const [modal, setModal] = useState(true);

  const handleOnClose = () => {
    setModal(false);
  };

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={{
        xs: 'auto',
        sm: '690px',
        lg: '742px',
      }}
    >
      {modal === true ? (
        <LanguageSelectionPage isOpen={modal} onClose={handleOnClose} />
      ) : (
        <Fade in={true} timeout={1200}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent={{
              xs: 'justify-between',
              md: 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              className="simplePage"
              height={{
                xs: 'auto',
                md: '400px',
              }}
              width="100%"
              justifyContent="center"
              // maxWidth="500px"
              boxSizing="border-box"
              mt={{
                xs: 'auto',
                md: '0px',
              }}
              sx={{
                gap: '20px',
                xs: { fontSize: '18px' },
                sm: { fontSize: '20px' },
                boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7);',
              }}
            >
              <Typography>
                {GetPageText('survey_transitiongeneral_1')}
              </Typography>
              <Typography>
                {GetPageText('survey_transitiongeneral_2')}
              </Typography>
              <Typography>
                {GetPageText('survey_transitiongeneral_3')}
              </Typography>
              <Typography>
                {GetPageText('survey_transitiongeneral_4')}
              </Typography>
            </Box>

            <Box
              width="100%"
              maxWidth={{
                xs: '320px',
                lg: '350px',
              }}
              boxSizing="boder-box"
              mt={{
                xs: 'auto',
                md: 2,
              }}
              mb={{ xs: 1, sm: 2 }}
              ml={1.5}
            >
              <Button
                style={{
                  backgroundColor: '#003865',
                  flex: 1,
                  color: '#ffffff',
                  height: '60px',
                  width: '100%',
                  boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
                }}
                variant="contained"
                onClick={props.next}
              >
                <Typography>
                  {`${GetPageText('survey_buttonnext')} >>`}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default Page1;
