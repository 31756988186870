import React from 'react';
import "./pages.css";
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';

function Page2(props) {
    return (
        <div className="root">
            <div className="page-container">
                <Fade in={true} timeout={1200}>
                    <div className="simplePage">
                        <p>{GetPageText('survey_transitionnorsia_2')}</p>
                        <p>
                            {GetPageText('survey_questionnorsia_1')}
                        </p>
                        <p>
                            {GetPageText('survey_transitionnorsia_3')}
                        </p>
                    </div>
                </Fade>
                <button onClick={props.next} className="btn page-2-btn-margin">{`${GetPageText('survey_buttonnext')} >>`}</button>
            </div>
        </div>
    );
}

export default Page2;