import React, { useEffect, useState } from 'react';
import './pages.css';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  Box,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import MifidRdoBox from './partials/MifidRdoBox';
import MifidRdoLabel from './partials/MifidRdoLabel';
import QuestionBox from './QuestionBox';
import Buttons from './partials/Buttons';

function Mifidpage2(props) {
  const { value, handleChange } = props;

  const [error, setError] = useState('');

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setError(value ? '' : 'Selection Required');
    console.log(value);
  }, [value]);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'space-between', md: 'center' }}
      alignItems="center"
      sx={{
        fontSize: {
          xs: '16px',
          sm: '18px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        flex={{
          xs: 1,
          md: 0,
        }}
        justifyContent="start"
        alignItems="center"
      >
        <Fade in={true} timeout={1000}>
          {/* Quetion box */}
          <QuestionBox>
            <Typography className="slider-typography-title">
              {GetPageText('survey_questionmifid_2')}
            </Typography>
          </QuestionBox>
          {/* End of question box */}
        </Fade>
        <Box
          width={{
            xs: '100%',
            md: 'auto',
          }}
        >
          <Fade in={true} timeout={2500}>
            <MifidRdoBox>
              {/* <p>{GetPageText('survey_satementmifid_3')}</p> */}
              <FormControl
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  height: '100%',
                  flex: 1,
                }}
              >
                <FormLabel
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Typography variant="h6">
                    {GetPageText('survey_questionmifid_3')}
                  </Typography>
                </FormLabel>
                {showError ? (
                  <Typography style={{ color: '#dc2626' }}>{error}</Typography>
                ) : null}
                <RadioGroup
                  value={value}
                  name="mifid_rdo_1"
                  onChange={handleChange}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 'auto',
                  }}
                >
                  <MifidRdoLabel
                    value={'no'}
                    control={<Radio />}
                    label={GetPageText('survey_answeroptionmifid_1')}
                  />
                  <MifidRdoLabel
                    value={'1-25%'}
                    control={<Radio />}
                    label={GetPageText('survey_answeroptionmifid_2')}
                  />
                  <MifidRdoLabel
                    value={'25-50%'}
                    control={<Radio />}
                    label={GetPageText('survey_answeroptionmifid_3')}
                  />
                  <MifidRdoLabel
                    value={'50-75%'}
                    control={<Radio />}
                    label={GetPageText('survey_answeroptionmifid_4')}
                  />
                  <MifidRdoLabel
                    value={'>75%'}
                    control={<Radio />}
                    label={GetPageText('survey_answeroptionmifid_5')}
                  />
                </RadioGroup>
              </FormControl>
            </MifidRdoBox>
          </Fade>
        </Box>
      </Box>

      <Buttons props={props} error={error} setShowError={setShowError} />
    </Box>
  );
}

export default Mifidpage2;
