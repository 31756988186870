import React, { useContext, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from './pages/App';

import ProtectedRoute from './ProtectedRoute';
import Survey from './components/Survey/surveyMain';
import Context from './context';
import reducer from './reducer';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  concat,
} from '@apollo/client';
import NotAuthorized from './components/NotAuthorized';
import ScenarioConditionFalse from './components/Survey/mifidscenariofalse';

import Confirm from './components/Confirm/Confirm';
import { GRAPHQL_URL } from './config';
import { ThemeProvider } from '@material-ui/core';
import theme from './MuiTheme';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  options: {
    reconnect: true,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const Root = () => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ApolloProvider client={client}>
          <Context.Provider value={{ state, dispatch }}>
            <Switch>
              <Route exact path="/authorize/:uuid" component={Confirm} />
              <Route path="/404" component={NotAuthorized} />
              <Route exact path="/" component={App} />
              <ProtectedRoute exact path="/survey" component={Survey} />
              <ProtectedRoute
                exact
                path="/mifid-condition-end"
                component={ScenarioConditionFalse}
              />
            </Switch>
          </Context.Provider>
        </ApolloProvider>
      </Router>
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
