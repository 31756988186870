import React from 'react';
import './pages.css';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import Buttons from './partials/Buttons';
import { Box, Typography } from '@material-ui/core';
import MifidTextBox from './partials/MifidTextBox';

function Page8(props) {
  return (
    <Box
      height="100%"
      width={{
        xs: 'auto',
        sm: '690px',
        md: '970px',
        lg: '1060px',
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Fade in={true} timeout={1200}>
        <MifidTextBox>
          <Typography>{GetPageText('survey_transitionnorsia_4')}</Typography>
          <Typography>{GetPageText('survey_questionnorsia_2')}</Typography>
        </MifidTextBox>
      </Fade>
      <Box
        width="100%"
        boxSizing="boder-box"
        display="flex"
        justifyContent="center"
        mt={{
          xs: 'auto',
          md: 0,
        }}
      >
        <Buttons props={props} />
      </Box>
    </Box>
  );
}

export default Page8;
