import React, { useState } from 'react';
import './pages.css';
import Experiment from './icons/AnimalTesting.js';
import Alcool from './icons/Alcohol.js';
import Gamgbling from './icons/Gambling.js';
import OGM from './icons/Gmo.js';
import Pumpjack from './icons/FossilFuel.js';
import Tank from './icons/Weapon.js';
import Tobacco from './icons/Tobacco.js';
import Toxic from './icons/Nuclear.js';
import Charbon from './icons/Coal.js';
import CancelIcon from '@material-ui/icons/Cancel';
import Fade from '@material-ui/core/Fade';
import { useMutation } from '@apollo/client';
import { CREATE_EXSECTORS } from '../../graphql/mutations';
import { DELETE_EXSECTORS } from '../../graphql/mutations';
import { GetPageText } from '../LanguageTextResolver';
import QuestionBox from './QuestionBox';
import { Box, Typography } from '@material-ui/core';
import Buttons from './partials/Buttons';

export function ItemParentContainer({ children }) {
  return (
    <Box
      maxHeight="500px"
      width={{
        xs: '100%',
        sm: '690px',
        md: '320px',
        lg: '350px',
      }}
      boxSizing="border-box"
      borderRadius="10px"
      backgroundColor="#f5f5f5"
      color="#ffffff"
      border="0.25px #003865 solid"
      boxShadow="-3px 3px 5px rgba(0, 0, 0, 0.7)"
      fontSize="15px"
      padding="10px"
    >
      {children}
    </Box>
  );
}

function Page14(props) {
  const { selectedData } = props;
 
  const iconsArr = [
    {
      icon: <Charbon fill="#003865" />,
      text: GetPageText('survey_exclusionoption_1'),
      id: 'Coal',
      display: '',
    },
    {
      icon: <Pumpjack fill="#003865" />,
      text: GetPageText('survey_exclusionoption_2'),
      id: 'Fossil Fuels',
      display: '',
    },
    {
      icon: <OGM fill="#003865" />,
      text: GetPageText('survey_exclusionoption_4'),
      id: 'GMO',
      display: '',
    },
    {
      icon: <Toxic fill="#003865" />,
      text: GetPageText('survey_exclusionoption_3'),
      id: 'Nuclear',
      display: '',
    },
    {
      icon: <Gamgbling fill="#003865" />,
      text: GetPageText('survey_exclusionoption_9'),
      id: 'Gambling',
      display: '',
    },
    {
      icon: <Experiment fill="#003865" />,
      text: GetPageText('survey_exclusionoption_5'),
      id: 'Animal Testing',
      display: '',
    },
    {
      icon: <Alcool fill="#003865" />,
      text: GetPageText('survey_exclusionoption_6'),
      id: 'Alcohol',
      display: '',
    },
    {
      icon: <Tobacco fill="#003865" />,
      text: GetPageText('survey_exclusionoption_7'),
      id: 'Tobacco',
      display: '',
    },
    {
      icon: <Tank fill="#003865" />,
      text: GetPageText('survey_exclusionoption_8'),
      id: 'Weapon',
      display: '',
    },
  ];

  const selectedIconsArr = [
    {
      icon: <Charbon fill="#fff" />,
      text: GetPageText('survey_exclusionoption_1'),
      id: 'Coal',
      display: 'none',
    },
    {
      icon: <Pumpjack fill="#fff" />,
      text: GetPageText('survey_exclusionoption_2'),
      id: 'Fossil Fuels',
      display: 'none',
    },
    {
      icon: <OGM fill="#fff" />,
      text: GetPageText('survey_exclusionoption_4'),
      id: 'GMO',
      display: 'none',
    },
    {
      icon: <Toxic fill="#fff" />,
      text: GetPageText('survey_exclusionoption_3'),
      id: 'Nuclear',
      display: 'none',
    },
    {
      icon: <Gamgbling fill="#fff" />,
      text: GetPageText('survey_exclusionoption_9'),
      id: 'Gambling',
      display: 'none',
    },
    {
      icon: <Experiment fill="#fff" />,
      text: GetPageText('survey_exclusionoption_5'),
      id: 'Animal Testing',
      display: 'none',
    },
    {
      icon: <Alcool fill="#fff" />,
      text: GetPageText('survey_exclusionoption_6'),
      id: 'Alcohol',
      display: 'none',
    },
    {
      icon: <Tobacco fill="#fff" />,
      text: GetPageText('survey_exclusionoption_7'),
      id: 'Tobacco',
      display: 'none',
    },
    {
      icon: <Tank fill="#fff" />,
      text: GetPageText('survey_exclusionoption_8'),
      id: 'Weapon',
      display: 'none',
    },
  ];

  const [create_exsector, { ...create_other }] = useMutation(CREATE_EXSECTORS);
  const [delete_exsector, { ...delete_other }] = useMutation(DELETE_EXSECTORS);

  const [icons, setIcons] = useState(
    iconsArr.filter((i) => !selectedData.includes(i.id))
  );
  const [selectedIcons, setSelectedIcons] = useState(
    selectedIconsArr.filter((i) => selectedData.includes(i.id))
  );

  const handle_mutation_create = (surveydef_id, sector) => {
    if(typeof surveydef_id!=='number' || typeof sector !=='string')
                throw new Error('Arguments are not valid')
            
         
            create_exsector({
                variables:{surveydef_id:surveydef_id,
                sector:sector}
            }).then((response)=>{

            })
  };

  const handle_mutation_delete = (surveydef_id, sector) => {
    if(typeof surveydef_id!=='number' || typeof sector !=='string')
                throw new Error('Arguments are not valid')

        delete_exsector({
            variables:{surveydef_id:surveydef_id,
            sector:sector}
        }).then((response)=>{
            
        })
  };

  const handleSelect = (id) => {
    const selectedIcon = selectedIconsArr.find((i) => i.id === id);
    const newIconsArr = icons.map((i) => {
      if (i.id === id) {
        return { ...i, display: 'none' };
      }
      return i;
    });
    setIcons(newIconsArr);
    setSelectedIcons([...selectedIcons, selectedIcon]);
    props.handleSelectedIcons(selectedIcon.id);
    handle_mutation_create(props.surveydef_id, selectedIcon.id);
  };

  const handleUnSelect = (id, iconName) => {
    const newIconsArr = icons.map((i) => {
      if (i.id === id) {
        return { ...i, display: '' };
      }
      return i;
    });
    setIcons(newIconsArr);
    setSelectedIcons(selectedIcons.filter((i) => i.id !== id));
    props.handleUnSelect(id);
    handle_mutation_delete(props.surveydef_id, id);
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'space-between', md: 'center' }}
      alignItems="center"
      sx={{
        fontSize: {
          xs: '16px',
          sm: '18px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        flex={{
          xs: 1,
          md: 0,
        }}
        justifyContent="start"
        alignItems="center"
      >
        <Fade in={true} timeout={1000}>
          <QuestionBox>
            <Typography className="slider-typography-title">
              {GetPageText('survey_questionnorsia_3')}
            </Typography>
          </QuestionBox>
        </Fade>
        <Box
          width={{
            xs: '100%',
            sm: '690px',
            md: '640px',
            lg: '700px',
          }}
        >
          <Box
            display="flex"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            height={{
              xs: 'auto',
              md: '400px',
            }}
            sx={{
              gap: '10px',
            }}
            width={{
              xs: '100%',
              sm: '690px',
              md: '640px',
              lg: '700px',
            }}
          >
            <Fade in={true} timeout={2500}>
              <ItemParentContainer>
                <div className="icons-container">
                  {icons.map((i) => (
                    <Box
                      display={i.display}
                      style={{
                        backgroundColor: `${
                          i.display === 'none' ? '#F5F5F5' : ''
                        }`,
                        cursor: `${i.display === 'none' ? 'auto' : ''}`,
                        boxShadow: `${
                          i.display === 'none' ? '0px 0px 0px' : ''
                        }`,
                      }}
                      onClick={() => handleSelect(i.id)}
                      className="icon"
                    >
                      {i.icon}
                      <Typography
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        {i.text}
                      </Typography>
                    </Box>
                  ))}
                </div>
              </ItemParentContainer>
            </Fade>
            <Fade in={true} timeout={3000}>
              <ItemParentContainer>
                <div className="icons-container">
                  {selectedIcons.map((i) => (
                    <Box
                      className="icon selected-icon"
                      sx={{ cursor: 'auto', position: 'relative' }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '-20px',
                          right: '-10px',
                          zIndex: 99,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleUnSelect(i.id, i.text)}
                      >
                        <CancelIcon
                          style={{
                            fontSize: '30px',
                            backgroundColor: '#f39200',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>

                      {i.icon}
                      <Typography
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        {i.text}
                      </Typography>
                    </Box>
                  ))}
                </div>
              </ItemParentContainer>
            </Fade>
          </Box>
        </Box>
      </Box>
      <Buttons props={props} />
    </Box>
  );
}

export default Page14;
