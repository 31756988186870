import {gql} from '@apollo/client';

export const GET_AUTHORIZED=gql`query($uuid:String!)
{
    get_authorized(uuid: $uuid)
    {
        surveydef_id,
        questions {
            question_id,
            answer
        },
        exsectors{
            sector
        },
        mifid{
            mifid_slider_1,
            mifid_slider_2,
            mifid_rdo_1,
            mifid_rdo_2,
            mifid_rdo_3
        }
    }
}
`;