import React, { useState,useEffect } from 'react';
import './pages.css';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import { useHistory } from 'react-router-dom';
import MifidTextBox from './partials/MifidTextBox';
import { Box, Button, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import {
  STORE_SURVEYSTATE,
  CREATE_QUESTIONS
} from '../../graphql/mutations';

function LastPage(props) {
  const [create_questions, { ...question_other }] =
    useMutation(CREATE_QUESTIONS);
    const [store_surveystate, { ...surveystate_other }] =
    useMutation(STORE_SURVEYSTATE);
  const {get_variables,nextPage}=props;
  const history = useHistory();
  const [loading,setLoading]=useState(true);

  useEffect( async ()=>{
      const variables=await get_variables();

      create_questions({variables:variables})
      .then((response)=>{
          store_surveystate({variables:{surveydef_id:variables.surveydef_id}}).then((response)=>{
              if (response.data.store_surveystate===true)
              {
                  //nextPage();
              }
          })  
      },
      (error)=>{
          console.log(error);
      })
  },[])

  

  return ( 
    <Box
      height="100%"
      width="100%"
      minWidth={{
        xs: 'auto',
        sm: '690px',
        md: '970px',
        lg: '1060px',
      }}
      maxWidth={{
        sm: '690px',
        md: '970px',
        lg: '1060px',
      }}
      mx="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Fade in={true} timeout={1000}>
        
          <MifidTextBox>
            <Typography
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
              }}
            >
              { 
              question_other.loading || surveystate_other.loading
              ? '...'
              :
              
                GetPageText('survey_transitiongeneral_8')
              }
            </Typography>  
          </MifidTextBox>  
      </Fade>

      <Box
        display="flex"
        width="100%"
        maxWidth={{
          xs: '320px',
          lg: '350px',
        }}
        justifyContent="center"
        alignItems="center"
        sx={{
          gap: '10px',
        }}
        mt={{
          xs: 'auto',
          md: 2,
        }}
        mb={{ xs: 1, sm: 2 }}
        ml={1.5}
      >
        
        <Button
          style={{
            backgroundColor: '#003865',
            color: '#ffffff',
            flex: 1,
            height: '60px',
            boxSizing: 'border-box',
            boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
          }}
          variant="contained"
          onClick={() => {
            nextPage();
          }}
          disabled={question_other.loading || surveystate_other.loading}
        >
          <Typography>{`${GetPageText('survey_buttonclose')}`}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default LastPage;
