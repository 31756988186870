import { createTheme } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '10px',
      },
    },
  },
});

export default theme;
