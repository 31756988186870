import React, { useState } from 'react';
import './pages.css';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import { useHistory } from 'react-router-dom';
import MifidTextBox from './partials/MifidTextBox';
import { Box, Button, Typography } from '@material-ui/core';
import Footer from '../Footer';
import Navbar from '../Navbar';

function ScenarioConditionFalse(props) {
  const history = useHistory();

  return (
    <Box className="App">
      <Navbar page={10} />
      <Box
        height="100%"
        minWidth={{
          xs: 'auto',
          sm: '690px',
          md: '970px',
          lg: '1010px',
        }}
        maxWidth={{
          sm: '690px',
          md: '970px',
          lg: '1010px',
        }}
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Fade in={true} timeout={1000}>
          <MifidTextBox>
            <Typography>{GetPageText('survey_transitiongeneral_8')}</Typography>
          </MifidTextBox>
        </Fade>

        <Box
          display="flex"
          width="100%"
          maxWidth={{
            xs: '320px',
            lg: '350px',
          }}
          justifyContent="center"
          alignItems="center"
          sx={{
            gap: '10px',
          }}
          mt={{
            xs: 'auto',
            md: 2,
          }}
          mb={{ xs: 1, sm: 2 }}
          ml={1.5}
        >
          <Button
            style={{
              backgroundColor: '#003865',
              color: '#ffffff',
              flex: 1,
              height: '60px',
              boxSizing: 'border-box',
              boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
            }}
            variant="contained"
            onClick={() => {
              history.push('/');
            }}
          >
            <Typography>{`${GetPageText('survey_buttonclose')}`}</Typography>
          </Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default ScenarioConditionFalse;
