import React, { useState } from 'react';
import './pages.css';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';

import MifidTextBox from './partials/MifidTextBox';
import { Box, Button, Typography } from '@material-ui/core';

function Scenario1p2(props) {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minWidth={{
        xs: 'auto',
        sm: '690px',
        md: '970px',
        lg: '1010px',
      }}
      maxWidth={{
        sm: '690px',
        md: '970px',
        lg: '1010px',
      }}
    >
      <Fade in={true} timeout={1000}>
        <MifidTextBox>
          <Typography>{GetPageText('survey_transitionnorsia_2')}</Typography>
          <Typography>{GetPageText('survey_questionnorsia_1')}</Typography>
          <Typography>{GetPageText('survey_transitionnorsia_3')}</Typography>
        </MifidTextBox>
      </Fade>

      <Box
        width="100%"
        maxWidth={{
          xs: '320px',
          lg: '350px',
        }}
        boxSizing="boder-box"
        mt={{
          xs: 'auto',
          md: 2,
        }}
        mb={{ xs: 1, sm: 2 }}
        ml={1.5}
      >
        <Button
          onClick={props.next}
          variant="outlined"
          color="#003865"
          className="btn"
          fullWidth
          style={{
            backgroundColor: '#003865',
            color: '#ffffff',
            height: '60px',
            boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
          }}
        >
          <Typography>{`${GetPageText('survey_buttonnext')} >>`}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default Scenario1p2;
