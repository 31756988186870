import React from "react";
import { FormControlLabel} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

const CustomRdoLabel=withStyles({
    root: {
        
       
        
    },
})(FormControlLabel);



export default  function MifidRdoLabel(props){
    
    const {...params}=props;
        return(
            <CustomRdoLabel {...params}/>
        );
}
