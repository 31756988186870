import React from 'react';
import "./pages.css";
import Fade from '@material-ui/core/Fade';

function MessageBox() {
    return (
        <div>
            <Fade in={true} timeout={1200}>
                <div className="root">
                    <div className="page-container">
                        <div className="simplePage">
                            <p>Dear Madam/Sir</p>
                            <p>
                                Thank you for filling in the questionnaire. Your answers have
                                been correctly saved and will be processed to establish your values profile.
                            </p>
                            <p>Norsia Team</p>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
}

export default MessageBox;