import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { GetPageText } from '../../LanguageTextResolver';

const Buttons = ({ props, error, setShowError }) => {
  const handleClick = () => {
    if (error) {
      setShowError(true);
    } else {
      props.next();
    }
  };

  return (
    <Box
      width="100%"
      maxWidth={{
        xs: '320px',
        lg: '350px',
      }}
      display="flex"
      sx={{
        gap: '10px',
      }}
      ml={1.5}
      my={{
        xs: 1,
        sm: 2,
      }}
    >
      <Button
        style={{ height: '60px', boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)' }}
        variant="contained"
        onClick={props.back}
      >
        <Typography>{`<<`}</Typography>
      </Button>
      <Button
        style={{
          backgroundColor: '#003865',
          flex: 1,
          color: '#ffffff',
          height: '60px',
          boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
        }}
        variant="contained"
        onClick={handleClick}
      >
        <Typography>{`${GetPageText('survey_buttonnext')} >>`}</Typography>{' '}
      </Button>
    </Box>
  );
};

export default Buttons;
