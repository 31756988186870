import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Link } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Navbar = ({ page }) => {
  const [display, setDisplay] = useState(page === 1 ? 'flex' : 'none');

  useEffect(() => {
    setDisplay(page === 1 ? 'flex' : 'none');
    console.log(display);
    console.log(page === 1 ? 'flex' : 'none');
    console.log(page);
  }, [page]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  useEffect(() => {
    console.log('screen', isSmallScreen);
  }, [isSmallScreen]);

  if (display === 'none' && isSmallScreen)
    return <Box marginBottom="8px"></Box>;

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="end"
    >
      <Link href="http://www.norsia.ch/" target="_blank">
        <Box
          height={70}
          width={100}
          position="relative"
          mt={{
            xs: 1,
            sm: 2,
          }}
          mx={2}
        >
          <img
            src="/norsia-logo.png"
            alt="logo"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Link>
      <Typography
        variant="h5"
        className="navbar-typography"
        style={{
          borderBottom: '2px solid #F39200',
          width: '500px',
          fontWeight: 'bold',
        }}
      >
        Leveraging Investor Values
      </Typography>
    </Box>
  );
};

export default Navbar;
