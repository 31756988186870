import {createContext} from 'react';

const Context=createContext({

    isAuthorized:false,
    surveyContainer:null,
    selectedLanguage:null

});

export default Context;