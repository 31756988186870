import React, { useState } from 'react';
import { Box } from '@material-ui/core';

const QuestionContainer = ({ children }) => {
  return (
    <Box
      display="flex"
      width={{
        sm: '690px',
        md: '640px',
        lg: '700px',
      }}
      textAlign="center"
      alignItems="center"
      justifyContent="space-around"
      boxSizing="border-box"
      p="10px"
      flex={1}
      sx={{
        border: '#003865 0.25px solid',
        borderRadius: '5px',
        boxShadow: '-2.5px 2.5px 8px rgba(0, 0, 0, 0.7);',
        gap: '15px',
      }}
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default QuestionContainer;

{
  /* <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      textAlign="center"
      alignItems="center"
      justifyContent="space-around"
      fontSize={{
        xs: '12px',
        md: '16px',
      }}
      width={{
        sm: '690px',
        md: '640px',
        lg: '700px',
      }}
      maxHeight={{
        xs: 'auto',
        md: '150px',
      }}
      boxSizing="border-box"
      p="10px"
      flex={1}
      sx={{
        border: '#003865 0.25px solid',
        borderRadius: '5px',
        boxShadow: '-2.5px 2.5px 8px rgba(0, 0, 0, 0.7);',
        gap: '5px',
      }}
    ></Box> */
}
