import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@material-ui/core';

const iOSBoxShadow =
  '0 0 0 rgba(0,0,0,0.1),0 0 0 rgba(0,0,0,0.1),0 0 0 0 rgba(0,0,0,0.01)';

const CustomSlider = withStyles({
  root: {
    color: 'transparent',
    height: 2,
    padding: '20px 0 10px 0',
  },
  thumb: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    boxShadow: iOSBoxShadow,
    marginLeft: -12,
    width: 24,
    height: 24,
    '&:focus, &:hover, &$active': {
      boxShadow: iOSBoxShadow,
    },
  },
  valueLabel: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif'",
    fontWeight: 700,
    fontSize: 16,
    '& *': {
      background: 'transparent',
      color: '#ffffff',
    },
  },
  track: {
    height: 11,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    color: '#003865',
  },
  rail: {
    height: 11,
    opacity: 0.5,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const CustumSlider = (props) => {
  const {...params}=props;
  return (
    <CustomSlider
      aria-labelledby="continuous-slider"
      {...params}
      ThumbComponent={(props) => (
        <Box {...props} display="flex" flexDirection="column" marginTop="-18px">
          <Typography
            style={{
              color: '#f39200',
              fontSize: '16px',
              marginBottom: '-5px',
            }}
          >
            {props['aria-valuenow']}
          </Typography>
          <CircleIcon
            style={{
              fontSize: 15,
              color: '#ffffff',

              borderRadius: '50%',
              backgroundColor: '#f39200',
              padding: '4.5px',
            }}
          />
        </Box>
      )}
    />
  );
};

export default CustumSlider;
