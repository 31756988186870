import {gql} from '@apollo/client';


export const CREATE_QUESTIONS=gql`
mutation($surveydef_id:Int!,$input:[question_input]){
    create_questions(surveydef_id:$surveydef_id,input:$input)
}
`

export const STORE_SURVEYSTATE=gql`
    mutation($surveydef_id:Int!) {
        store_surveystate(surveydef_id:$surveydef_id)
    }
`;

export const CREATE_MIFIDS=gql`
    mutation($surveydef_id:Int!,$mifid_slider_1:Float!
        $mifid_slider_2:Float!
        $mifid_rdo_1:String!
        $mifid_rdo_2:String!
        $mifid_rdo_3:String!){
        create_mifids(surveydef_id:$surveydef_id,
                mifid_slider_1:$mifid_slider_1,
                mifid_slider_2:$mifid_slider_2,
                mifid_rdo_1:$mifid_rdo_1,
                mifid_rdo_2:$mifid_rdo_2,
                mifid_rdo_3:$mifid_rdo_3
            )
    }
`

export const CREATE_EXSECTORS=gql`
mutation($surveydef_id:Int!,$sector:String!){
    create_exsector(surveydef_id:$surveydef_id,sector:$sector)
}
`



export const DELETE_EXSECTORS=gql`
mutation($surveydef_id:Int!,$sector:String!){
    delete_exsector(surveydef_id:$surveydef_id,sector:$sector)
}
`