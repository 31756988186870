import React,{Component,useContext,useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import Context from '../../context';
import {ProgressBar} from './Spinner';
import {useLazyQuery} from '@apollo/client';
import { GET_AUTHORIZED } from '../../graphql/queries';



export default function Confirm(props){
    const [get_authorized,{...authorized_callback}]
    =useLazyQuery(GET_AUTHORIZED)

    const params = useParams();
    const {dispatch}=useContext(Context);
    const [wait,setWait]=useState(true)
    
    useEffect(async ()=>{
       
        await get_authorized({variables:params})
        .then((response)=>{
            if(!response.data.get_authorized)
                return;
            const {...survey_container}=response.data.get_authorized;
            if(typeof survey_container.surveydef_id !=='number')
                throw new Error('Survey not defined');
            
            dispatch({type:"AUTHORIZE",payload:survey_container})
            setWait(false);
        })
        .then((error)=>{});
    }, [])
      

        return(
            <div>
                {
                    wait?
                    <ProgressBar/>
                    :<Redirect  to='/'/>
                }
            </div>
        )
}
