import React from 'react';


export default function NotAuthorized(props){

    return (
    <div>
        {'404'}
    </div>)
    
}