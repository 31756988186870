import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default function MifidRdoBox(props) {
  const { children } = props;
  return (
    <Box
      sx={{
        border: '0.5px gray solid',
        borderRadius: 10,
        padding: '20px',
        width: '100%',
        minWidth: {
          sm: '690px',
          md: '640px',
          lg: '700px',
        },
        height: '400px',
        maxWidth: {
          sm: '690px',
          md: '640px',
          lg: '700px',
        },
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',

        boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7);',
      }}
    >
      {children}
    </Box>
  );
}
