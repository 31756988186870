import React,{useContext} from "react";
import Context from "../context";

const GetPageTextObject=()=>{
    const {state}=useContext(Context);
    const {selectedLanguage}=state;
    const {...elements}=selectedLanguage
    return elements;
}

export const GetPageText=(key)=>{
    const elements=GetPageTextObject();
    return elements[key]
}

export const GetPageKeys=()=>{
    const elements=GetPageTextObject();
    return Object.keys(elements);
}