import React, { useContext, useState, useEffect } from 'react';
import Mifidpage1 from './mifidpage1';
import Mifidpage2 from './mifidpage2';
import Mifidpage3 from './mifidpage3';
import Mifidpage4 from './mifidpage4';
import Scenario1p1 from './scenario1p1';
import Scenario1p2 from './scenario1p2';
import ScenarioCondition from './mifidscenariocondition';
import ScenarioConditionFalse from './mifidscenariofalse';
import Page1 from './page1';
import Page2 from './page2';
import Page3 from './page3';
import Page4 from './page4';
import Page5 from './page5';
import Page6 from './page6';
import Page7 from './page7';
import Page8 from './page8';
import Page9 from './page9';
import Page10 from './page10';
import Page11 from './page11';
import Page12 from './page12';
import Page13 from './page13';
import Page14 from './page14';
import LastPage from './lastpage';

import MessageBox from './message_box';
import Pagination from './pagination';
import Pagination2 from './pagination-page-14-15';
import Context from '../../context';
import { useHistory } from 'react-router-dom';

import {
 
  CREATE_MIFIDS,
  
} from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Box } from '@material-ui/core';

function Survey() {
  
  const [create_mifids, { ...mifid_other }] = useMutation(CREATE_MIFIDS);
  
  const { state } = useContext(Context);
  const history = useHistory();

  const [page, setPage] = useState(currentPage === 1);

  useEffect(() => {
    setPage(currentPage === 1);
  }, [currentPage]);

  const [mifid, setMifid] = useState(() => {
     if(state.surveyContainer.mifid.length!==0)
        {
            return state.surveyContainer.mifid;
        }
  });

  const [questions, setQuestions] = useState(() => {
    let items = {};
    if(state.surveyContainer.questions.length!==0)
        {
   
            state.surveyContainer.questions.map(q=>{
                items[parseInt(q.question_id)]=q.answer;
            })
        }
          //fill misisng values
        Array(27).fill(5).map((v,i)=>{
            console.log(items.hasOwnProperty(i+1))
            if(!items.hasOwnProperty(i+1))
                items[i+1]=v;
        })  
    return { ...items };
  });

  const [iconsNamePage14, setIconsNamePage14] = 
        useState(()=>{
            return state.surveyContainer.exsectors.map((item)=>{
                    return item.sector;
            })
    });


  const [selectedDataPage15, setDataPage15] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const evaluateMifidCondition = () => {
    if ((mifid.mifid_slider_1 + mifid.mifid_slider_2) / 2.0 >= 5) return true;
    else if (mifid.mifid_slider_2 >= 5) return true;
    else if (
      mifid.mifid_rdo_1 !== 'no' ||
      mifid.mifid_rdo_2 !== 'no' ||
      mifid.mifid_rdo_3 !== 'no'
    )
      {return true;}
    return false;
  };

  const finishMifid = async () => {
    const variables={surveydef_id:state.surveyContainer.surveydef_id,
            ...mifid}
       
        create_mifids({variables:variables})
        .then((response)=>{
            
        },
        (error)=>{
            console.log(error);
        })
  };

  const nextPage = () => {
    if(currentPage+1===6){
            finishMifid();

            if(!evaluateMifidCondition()){
                history.push('/mifid-condition-end');
            }
        }
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const setSelectedIconsNamePage14 = (iconName) => {
    setIconsNamePage14([...iconsNamePage14, iconName]);
  };

  const handleUnSelectPage14 = (iconName) => {
    setIconsNamePage14(iconsNamePage14.filter((i) => i !== iconName));
  };

  const setSelectedDataPage15 = (data) => {
    setDataPage15([...selectedDataPage15, data]);
  };

  const handleUnSelectedDataPage15 = (data) => {
    setDataPage15(selectedDataPage15.filter((i) => i !== data));
  };

  const finishSurvey =  () => {


    let questions_to_database=[]
        for (const [key,value] of Object.entries(questions)){
            questions_to_database.push({question_id:parseInt(key),answer:value})
        }
        
        const variables={surveydef_id:state.surveyContainer.surveydef_id
          ,input:questions_to_database}
    return variables
    
  };

  const handleChange = (question, value) => {
    setQuestions({ ...questions, [question]: value });
  };

  const handleMifidRdoChange = (event) => {
    setMifid({ ...mifid, [event.target.name]: event.target.value });
  };

  const handleMifidSliderChange = (name, value) => {
    setMifid({ ...mifid, [name]: value });
  };

  if (
    //question_other.loading ||
    mifid_other.loading //||
   // surveystate_other.loading
  )
    return <div>Loading...</div>;

  return (
    <Box className="App">
      <Navbar page={currentPage} />
      {currentPage === 1 && <Page1 next={nextPage} back={previousPage} />}
      {currentPage === 2 && (
        <Mifidpage1
          value1={mifid.mifid_slider_1}
          value2={mifid.mifid_slider_2}
          handleChange={handleMifidSliderChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 3 && (
        <Mifidpage2
          value={mifid.mifid_rdo_1}
          handleChange={handleMifidRdoChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 4 && (
        <Mifidpage3
          value={mifid.mifid_rdo_2}
          handleChange={handleMifidRdoChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 5 && (
        <Mifidpage4
          value={mifid.mifid_rdo_3}
          handleChange={handleMifidRdoChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 6 && (
        <ScenarioCondition next={nextPage} endcondition={previousPage} />
      )}
      {currentPage === 7 && <Scenario1p1 next={nextPage} back={previousPage} />}
      {currentPage === 8 && <Scenario1p2 next={nextPage} back={previousPage} />}
      {currentPage === 9 && (
        <Page3
          q1={questions[1]}
          q2={questions[2]}
          q3={questions[3]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 10 && (
        <Page4
          q1={questions[4]}
          q2={questions[5]}
          q3={questions[6]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}{' '}
      {currentPage === 11 && (
        <Page5
          q1={questions[7]}
          q2={questions[8]}
          q3={questions[9]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 12 && (
        <Page6
          q1={questions[10]}
          q2={questions[11]}
          q3={questions[12]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 13 && (
        <Page7
          q1={questions[13]}
          q2={questions[14]}
          q3={questions[15]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 14 && <Page8 next={nextPage} back={previousPage} />}
      {currentPage === 15 && (
        <Page9
          q1={questions[16]}
          q2={questions[17]}
          q3={questions[18]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 16 && (
        <Page10
          q1={questions[19]}
          q2={questions[20]}
          q3={questions[21]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 17 && (
        <Page11
          q1={questions[22]}
          q2={questions[23]}
          q3={questions[24]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 18 && (
        <Page12
          q1={questions[25]}
          q2={questions[26]}
          q3={questions[27]}
          handleChange={handleChange}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 19 && (
        <Page14
          selectedData={iconsNamePage14}
          handleSelectedIcons={setSelectedIconsNamePage14}
          handleUnSelect={handleUnSelectPage14}
          surveydef_id={state.surveyContainer.surveydef_id}
          next={nextPage}
          back={previousPage}
        />
      )}
      {currentPage === 20 && <LastPage  get_variables={finishSurvey} nextPage={nextPage}/>}
      <Footer />
    </Box>
  );
}

export default Survey;
