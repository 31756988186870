import React, { useState,useContext } from "react";
import { Dialog , DialogActions, Box,Grid,FormControl,
    InputLabel,Select,MenuItem} from "@material-ui/core";
import Context from '../context';
import data from '../data/languages.json';





export default function LanguageSelectionPage(props) {
    const {isOpen,onClose}=props
    const languages=['English','Espanol','Francais','Deutsch','Italiano']
    const [language,setLanguage]=useState(languages[0])

    const {dispatch}=useContext(Context);

    const ResolveLanguage=(lang)=>{
        switch(lang){
            case 'English': return data.English;
            case 'Espanol': return data.Spanish;
            case 'Francais':return data.French;
            case 'Deutsch': return data.German;
            case 'Italiano':return data.Italian;
            default: throw Error('Language not recognized')
        }
    }

    const handleChange=(event)=>{
           
            setLanguage(event.target.value)
            dispatch({type:'SET_LANGUAGE',payload:ResolveLanguage(event.target.value)})

            onClose();
    }
    
    return(
    <Dialog
        open={isOpen}    
    >
        <DialogActions >
            <Box  sx={{ minWidth: 275,minHeight:100,padding:5 }}>         
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select language</InputLabel>
                        <Select 
                            label='Language'
                            onChange={handleChange}
                            displayEmpty
                        fullWidth>
                                {
                                languages.map(language=>(
                                    <MenuItem value={language}>{language}</MenuItem>
                                ))
                                }
                        </Select>
                    </FormControl>     
            </Box>
        </DialogActions> 
    </Dialog>)
}