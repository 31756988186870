import React, { useState } from 'react';
import './pages.css';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import { useHistory } from 'react-router-dom';
import MifidTextBox from './partials/MifidTextBox';
import { Box, Button, Typography } from '@material-ui/core';

function ScenarioCondition(props) {
  const history = useHistory();

  return (
    <Box
      height="100%"
      width={{
        xs: 'auto',
        sm: '690px',
        md: '970px',
        lg: '1010px',
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Fade in={true} timeout={1000}>
        <MifidTextBox>
          <Typography>{GetPageText('survey_transitiongeneral_5')}</Typography>
          <Typography>{GetPageText('survey_transitiongeneral_6')}</Typography>
        </MifidTextBox>
      </Fade>

      <Box
        display="flex"
        width="100%"
        maxWidth={{
          xs: '320px',
          lg: '350px',
        }}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        sx={{
          gap: '10px',
        }}
        mt={{
          xs: 'auto',
          md: 2,
        }}
        mb={{
          xs: 1,
          sm: 2,
        }}
        ml={1.5}
      >
        <Button
          variant="contained"
          style={{
            flex: 1,
            height: '60px',
            boxSizing: 'border-box',
            boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
          }}
          onClick={() => history.push('/mifid-condition-end')}
        >
          <Typography>{GetPageText('survey_buttonno')}</Typography>
        </Button>
        <Button
          style={{
            backgroundColor: '#003865',
            color: '#ffffff',
            flex: 1,
            height: '60px',
            boxSizing: 'border-box',
            boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7)',
          }}
          variant="contained"
          onClick={props.next}
        >
          <Typography>{GetPageText('survey_buttonyesinterested')}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default ScenarioCondition;
