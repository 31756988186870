import React from 'react';
import "./pages.css";
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';

function Page13(props) {
    return (
        <div className="root">
            <div className="page-container">
                <Fade in={true} timeout={1200}>
                    <div className="simplePage2">
                        <p>
                            In order to personalize our services, we offer you the possibility to
                            exclude industries or sectors completely of your investment universe.
                        </p>
                        <p>
                            However, we draw your attention to the possible impact this could
                            have on the management of your portfolio in terms of risk or
                            performance.
                        </p>
                    </div>
                </Fade >
                <div style={{ width: 500 }} className="btn-container page-8-btn-margin">
                    <button onClick={props.back} className="btn back-btn2">{`<<`}</button>
                    <button onClick={props.next} className="btn next-btn2">{`${GetPageText('survey_buttonnext')} >>`}</button>
                </div>
            </div>
        </div>
    );
}

export default Page13;