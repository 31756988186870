import React,{Component, useContext} from 'react';
import {Route,Redirect} from 'react-router-dom';

import Context from './context';

const ProtectedRoute=({component:Component,...rest})=>{
    const {state}=useContext(Context);

    return(
        <Route
        render={props=>
        !state.isAuthorized? <Redirect to='/404'/> : <Component {...props}/>
    }

        {...rest}
        />
    );
};


export default ProtectedRoute;