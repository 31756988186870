import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
        width: "50%"
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#003865',
    },
}))(LinearProgress);




const useStyles = makeStyles({
    root: {
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
});


function ProgressBar() {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (progress < 100) {
            setTimeout(() => {
                setProgress(progress + 10)
            }, 1000)
        }
    })

    return (
        <div className={classes.root}>
            <BorderLinearProgress   value={progress} />
        </div>
    );

}

export {ProgressBar};

