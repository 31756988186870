import React from 'react';
import '../pages.css';
import { Box } from '@material-ui/core';

export default function MifidTextBox(props) {
  const { children } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      height="400px"
      width={{
        xs: 'auto',
        sm: '690px',
        lg: '742px',
      }}
      boxSizing="border-box"
      mt={{
        xs: 'auto',
        md: 0,
      }}
      sx={{
        gap: '15px',
      }}
      className="simplePage-mifid"
    >
      {children}
    </Box>
  );
}
