import React, { useState } from 'react';
import './pages.css';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Fade from '@material-ui/core/Fade';
import { GetPageText } from '../LanguageTextResolver';
import QuestionBox from './QuestionBox';
import { Box, Typography, makeStyles } from '@material-ui/core';
import QuestionContainer from './partials/QuestionContainer';
import Buttons from './partials/Buttons';
import CustumSlider from '../../utils/CustomSlider';

function Page4(props) {
  const { handleChange, q1, q2, q3 } = props;
  const [key1, key2, key3] = [4, 5, 6];

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'space-between', md: 'center' }}
      alignItems="center"
      sx={{
        fontSize: {
          xs: '16px',
          sm: '18px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        flex={{
          xs: 1,
          md: 0,
        }}
        justifyContent="start"
        alignItems="center"
      >
        <QuestionBox>
          <Typography className="slider-typography-title">
            {GetPageText('survey_questionnorsia_1')}
          </Typography>
        </QuestionBox>

        <Fade in={true} timeout={2500}>
          <Box
            display="flex"
            flexDirection="column"
            height={{
              xs: 'auto',
              md: '400px',
            }}
            sx={{
              gap: '10px',
            }}
            width={{
              xs: '100%',
              md: 'auto',
            }}
          >
            <QuestionContainer>
              <Typography className="slider-typography-title">
                {GetPageText('survey_statementnorsia_4')}
              </Typography>
              <Box
                width="100%"
                minWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                maxWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                ml={1.5}
              >
                <Box class="slidecontainer">
                  <CustumSlider
                    aria-label="ios slider"
                    defaultValue={5}
                    min={0}
                    max={10}
                    step={1}
                    onChange={(evt, val) => handleChange(key1, val)}
                    value={q1}
                    valueLabelDisplay="on"
                  />
                </Box>
                <div className="slider-text">
                  <div
                    style={{
                      marginRight: 20,
                      marginTop: 5,
                    }}
                  >
                    <span>
                      <Typography className="slider-typography">
                        {GetPageText('survey_answerlimitnorsia_1')}
                      </Typography>
                    </span>
                  </div>
                  <div
                    style={{ marginLeft: 20, marginTop: 5, fontSize: '12px' }}
                  >
                    <Typography className="slider-typography">
                      {GetPageText('survey_answerlimitnorsia_2')}
                    </Typography>
                  </div>
                </div>
              </Box>
            </QuestionContainer>
            <QuestionContainer>
              <Typography className="slider-typography-title">
                {GetPageText('survey_statementnorsia_5')}
              </Typography>
              <Box
                width="100%"
                minWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                maxWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                ml={1.5}
              >
                <Box class="slidecontainer">
                  <CustumSlider
                    aria-label="ios slider"
                    defaultValue={5}
                    min={0}
                    max={10}
                    step={1}
                    onChange={(evt, val) => handleChange(key2, val)}
                    value={q2}
                    valueLabelDisplay="on"
                  />
                </Box>
                <div className="slider-text">
                  <div
                    style={{
                      marginRight: 20,
                      marginTop: 5,
                      fontSize: '12px',
                    }}
                  >
                    <span>
                      <Typography className="slider-typography">
                        {GetPageText('survey_answerlimitnorsia_1')}
                      </Typography>
                    </span>
                  </div>
                  <div
                    style={{ marginLeft: 20, marginTop: 5, fontSize: '12px' }}
                  >
                    {' '}
                    <Typography className="slider-typography">
                      {GetPageText('survey_answerlimitnorsia_2')}
                    </Typography>
                  </div>
                </div>
              </Box>
            </QuestionContainer>
            <QuestionContainer>
              <Typography className="slider-typography-title">
                {GetPageText('survey_statementnorsia_6')}
              </Typography>
              <Box
                width="100%"
                minWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                maxWidth={{
                  md: '310px',
                  lg: '330px',
                }}
                ml={1.5}
              >
                <Box class="slidecontainer">
                  <CustumSlider
                    aria-label="ios slider"
                    defaultValue={5}
                    min={0}
                    max={10}
                    step={1}
                    onChange={(evt, val) => handleChange(key3, val)}
                    value={q3}
                    valueLabelDisplay="on"
                  />
                </Box>
                <div className="slider-text">
                  <div
                    style={{
                      marginRight: 20,
                      marginTop: 5,
                      fontSize: '12px',
                    }}
                  >
                    <span>
                      <Typography className="slider-typography">
                        {GetPageText('survey_answerlimitnorsia_1')}
                      </Typography>
                    </span>
                  </div>
                  <div
                    style={{ marginLeft: 20, marginTop: 5, fontSize: '12px' }}
                  >
                    <Typography className="slider-typography">
                      {GetPageText('survey_answerlimitnorsia_2')}
                    </Typography>
                  </div>
                </div>
              </Box>
            </QuestionContainer>
          </Box>
        </Fade>
      </Box>
      <Buttons props={props} />
    </Box>
  );
}

export default Page4;
