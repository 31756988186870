import React from 'react';
import { Box } from '@material-ui/core';

const QuestionBox = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      textAlign="center"
      borderRadius="10px"
      boxSizing="border-box"
      height={{
        xs: 'fit-content',
        md: '400px',
      }}
      mb={{
        xs: '15px',
        md: '0px',
      }}
      mr={{
        xs: '0px',
        md: '10px',
      }}
      px="10px"
      mt={{
        sm: '15px',
        md: '0px',
      }}
      py={{
        xs: '10px',
        md: '0px',
      }}
      color="#ffffff"
      width={{
        xs: '100%',
        sm: '690px',
        md: '320px',
        lg: '350px',
      }}
      sx={{
        boxShadow: '-3px 3px 5px rgba(0, 0, 0, 0.7);',
        backgroundColor: '#003865',
      }}
    >
      {children}
    </Box>
  );
};

export default QuestionBox;
