import React from 'react';
import './App.css';
import Landing from './Landing';
import Survey from '../components/Survey/surveyMain';
import { Box, ThemeProvider } from '@material-ui/core';
import theme from '../MuiTheme';

function App() {
  return (
    <Box height="100%" className="App">
      <Landing />
    </Box>
  );
}

export default App;
