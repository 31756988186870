import React,{useContext} from 'react';
import {Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom';

import Context from '../context';

const Landing=()=>{
    const {state}=useContext(Context);
   
    return state.isAuthorized ? <Redirect to='/survey' /> : <Redirect to='/404' />;
};

export default Landing;