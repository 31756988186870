import React from 'react';
import './pagination.css';

function Pagination(props) {

    const pageNumbers = [];

    for (let i = 10; i <= Math.ceil(props.totalOfPages / props.perPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            {pageNumbers.map((number, i) => (
                <span style={{
                    display: i + 1 === 1 || i + 1 === 2 || i + 1 === 8 || i + 1 === 13 ? "none" : "",
                    backgroundColor: (i + 1) === props.currentPage ?
                        "#f39200"
                        :
                        i < props.currentPage ?
                            "#003865"
                            :
                            "#bfbfbf"
                }} onClick={() => props.paginate(number)}></span>
            ))
            }
        </div>
    );
}

export default Pagination;