import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';

const Footer = () => {
  return (
    <Box
      width="100vw"
      height={{
        xs: '30px',
        sm: '75px',
      }}
      bgcolor="#003865"
      color="#ffffff"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display={{
          xs: 'none',
          md: 'flex',
        }}
        sx={{
          gap: 8,
        }}
        ml={{
          sm: 6,
          lg: 8,
        }}
      >
        <Typography className="slider-typography-title">Norsia SA</Typography>
        <Typography className="slider-typography-title">-</Typography>
        <Typography className="slider-typography-title">
          Pulse Incubator
        </Typography>
        <Typography className="slider-typography-title">-</Typography>
        <Typography className="slider-typography-title">
          Rue de Lyon 22
        </Typography>
        <Typography className="slider-typography-title">-</Typography>
        <Typography className="slider-typography-title">1201 Geneva</Typography>
        <Typography className="slider-typography-title">-</Typography>
        <Typography className="slider-typography-title">Switzerland</Typography>
      </Box>
      <Box
        display="flex"
        sx={{
          gap: 8,
        }}
        mr={{
          xs: 'auto',
          md: 6,
          lg: 8,
        }}
        ml={{
          xs: 'auto',
          md: 0,
        }}
      >
        <Typography color="#ffffff">
          <Link
            target="_blank"
            color="inherit"
            href="https://norsia.ch/privacy-policy"
          >
            Privacy Policy
          </Link>
        </Typography>

        <Typography className="slider-typography-title">-</Typography>
        <Typography color="#ffffff">
          <Link
            target="_blank"
            color="inherit"
            href="https://norsia.ch/terms-of-use/"
          >
            General Terms of Use
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
