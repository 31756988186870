import * as React from "react";

function SvgNuclear(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" {...props}>
      <path d="M38.816 25.26a1 1 0 00-.09-1.494c-1.942-1.522-4.27-2.327-6.727-2.327s-4.783.805-6.726 2.327a.997.997 0 00-.09 1.494l5.436 5.435c.255.256.63.352.977.256.321-.09.488-.089.807 0a1 1 0 00.978-.256l5.435-5.435zm-6.472 3.644a3.035 3.035 0 00-.687 0l-4.21-4.21c2.751-1.648 6.36-1.648 9.108 0l-4.211 4.21zM30.718 33.107a1.487 1.487 0 01-.144-.383 1.003 1.003 0 00-.711-.721l-7.425-1.99a1.004 1.004 0 00-.81.131 1.004 1.004 0 00-.439.694 10.817 10.817 0 001.349 6.988 10.811 10.811 0 005.378 4.661 1.006 1.006 0 00.82-.033.998.998 0 00.519-.637l1.989-7.426a1 1 0 00-.272-.979 1.346 1.346 0 01-.254-.305zm-3.084 7.038a8.807 8.807 0 01-3.364-3.315 8.81 8.81 0 01-1.19-4.571l5.751 1.541a2.979 2.979 0 00.344.596l-1.541 5.749zM42.811 30.84a.996.996 0 00-1.249-.825l-7.428 1.99a1.001 1.001 0 00-.712.726 1.315 1.315 0 01-.146.379c-.057.1-.137.197-.252.311-.26.252-.363.626-.271.977l1.988 7.426a1.001 1.001 0 001.339.669 10.82 10.82 0 005.379-4.661 10.831 10.831 0 001.352-6.992zm-3.082 5.988a8.795 8.795 0 01-3.363 3.314l-1.54-5.75a3.156 3.156 0 00.344-.594l5.751-1.541a8.826 8.826 0 01-1.192 4.571z" />
      <path d="M48 53h-1V11h1c1.654 0 3-1.346 3-3s-1.346-3-3-3H16c-1.654 0-3 1.346-3 3s1.346 3 3 3h1v42h-1c-1.654 0-3 1.346-3 3s1.346 3 3 3h32c1.654 0 3-1.346 3-3s-1.346-3-3-3zM19 15h26v34H19V15zm-4-7c0-.551.449-1 1-1h32a1.001 1.001 0 010 2H16c-.551 0-1-.449-1-1zm30 3v2H19v-2h26zM19 51h26v2H19v-2zm29 6H16a1 1 0 010-2h32a1 1 0 110 2z" />
    </svg>
  );
}

export default SvgNuclear;
